import {
  DateRangeCustomFilterEnum,
  DateRangeFilterEnum,
} from 'shared/components/date-picker/enum/date-range-filter-enum'

export const dateRangeFilterLocoKeys: Record<DateRangeFilterEnum, string> = {
  [DateRangeFilterEnum.last_30_days]: 'dashboard.datepicker.last_30_days',
  [DateRangeFilterEnum.this_month]: 'dashboard.datepicker.this_month',
  [DateRangeFilterEnum.last_month]: 'dashboard.datepicker.last_month',
}

export const dateRangeCustomFilterLocoKeys: Record<DateRangeCustomFilterEnum, string> = {
  [DateRangeCustomFilterEnum.all_time]: 'dashboard.datepicker.all_time',
  [DateRangeCustomFilterEnum.last_3_months]: 'dashboard.datepicker.last_3_months',
  [DateRangeCustomFilterEnum.evaluation_period]: 'dashboard.datepicker.last_pool_period',
}
