import en from 'date-fns/locale/en-US'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { default as ReactDatePicker } from 'react-datepicker'
import { Chip, DangerButton, PrimaryButton } from 'shared/components/button'
import {
  dateRangeCustomFilterLocoKeys,
  dateRangeFilterLocoKeys,
} from 'shared/components/date-picker/constants/date-range-filter-loco-keys'
import {
  DateRangeCustomFilterEnum,
  DateRangeFilterEnum,
} from 'shared/components/date-picker/enum/date-range-filter-enum'
import { setActiveFilterValue } from 'shared/components/date-picker/utills/set-active-filter-value'
import { getDefaultCustomFilter } from 'shared/components/date-picker/utills/set-custom-filter'
import { setDateSince } from 'shared/components/date-picker/utills/set-date-since'
import { setDateTill } from 'shared/components/date-picker/utills/set-date-till'
import LocalizeNamespaces from 'shared/constants/localize-namespaces.mjs'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ArrowIonicLeftIcon from 'shared/icons/arrow-ionic-left-icon'
import ArrowIonicRightIcon from 'shared/icons/arrow-ionic-right-icon'
import CalendarIcon from 'shared/icons/calendar-icon'
import { setDateWithTimezone, toISOStringWithoutTimezone } from 'shared/utils/date-time-format'
import { getDate } from 'shared/utils/get-dates'
import { getLanguageCodeByLocale } from '../../enums/language-code-enum'
import { FieldErrorAndDescription } from '../form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from '../form/field-label'

export type CustomFilterType = { slug: DateRangeCustomFilterEnum; handler: () => [Date, Date] }

interface DateRangePickerProps {
  date: [string | null, string | null]
  onChange: (date: [string | null, string | null]) => void
  error?: string
  label?: string
  light?: boolean
  required?: boolean
  placeholder?: string
  className?: string
  description?: string
  getDefaultDate?: () => [string, string]
  disabled?: boolean
  maxDate?: Date
  minDate?: Date
  small?: boolean
  customFilters?: CustomFilterType[]
}

export const DateRangePickerLoco = [LocalizeNamespaces.datepicker]

const DateRangePicker = ({
  date,
  onChange,
  label,
  required,
  light,
  placeholder,
  error,
  className,
  description,
  getDefaultDate,
  disabled,
  maxDate,
  minDate,
  small,
  customFilters,
}: DateRangePickerProps) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const datePickerRef = useRef<ReactDatePicker>(null)
  const [locale, setLocale] = useState<Locale>(en)

  const minAllowedDate = useMemo(
    () => (minDate && toISOStringWithoutTimezone(minDate)) ?? user?.createdAt,
    [minDate, user?.createdAt],
  )

  const getDateSince = (dateSinceValue: string | null) => {
    if (!user || !minAllowedDate || !dateSinceValue) return dateSinceValue
    if (getDate(minAllowedDate) > getDate(dateSinceValue)) return minAllowedDate
    return dateSinceValue
  }

  const [_, dateTill] = date
  const dateSince = getDateSince(date[0])

  const [selectedFilter, setSelectedFilter] = useState<
    DateRangeFilterEnum | DateRangeCustomFilterEnum
  >()
  const [activeFilter, setActiveFilter] = useState<
    DateRangeFilterEnum | DateRangeCustomFilterEnum
  >()

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    (dateSince && setDateWithTimezone(dateSince, user?.timezone)) || null,
    (dateTill && setDateWithTimezone(dateTill, user?.timezone)) || null,
  ])
  const [selectedDateSince, selectedDateTill] = dateRange
  const [appliedDateSince, setAppliedDateSince] = useState<Date | null>(
    (dateSince && setDateWithTimezone(dateSince, user?.timezone)) || null,
  )
  const [appliedDateTill, setAppliedDateTill] = useState<Date | null>(
    (dateTill && setDateWithTimezone(dateTill, user?.timezone)) || null,
  )

  useEffect(() => {
    if (!user || !minAllowedDate) return
    setActiveFilterValue({
      activeFilter,
      user,
      setDateRange,
      customFilters: customFilters || [getDefaultCustomFilter(minAllowedDate, user.timezone)],
    })
  }, [selectedFilter, activeFilter, user, customFilters, minAllowedDate])

  useEffect(() => {
    // We need it to fix this https://rollbar.com/systemeio/dashboard-frontend/items/1370/?item_page=0&#instances
    if (user && user.dashboardLocale) {
      if (user.dashboardLocale === 'en') {
        setLocale(require(`date-fns/locale/en-US/index.js`))
      } else if (user.dashboardLocale === 'zh') {
        setLocale(require(`date-fns/locale/zh-CN/index.js`))
      } else if (user.dashboardLocale === 'ua') {
        setLocale(require(`date-fns/locale/uk/index.js`))
      } else if (user.dashboardLocale === 'dk') {
        setLocale(require(`date-fns/locale/da/index.js`))
      } else if (user.dashboardLocale === 'no') {
        setLocale(require(`date-fns/locale/nb/index.js`))
      } else {
        setLocale(
          require(`date-fns/locale/${getLanguageCodeByLocale(user.dashboardLocale)}/index.js`),
        )
      }
    } else {
      setLocale(require(`date-fns/locale/en-US/index.js`))
    }
  }, [user])

  useEffect(() => {
    if (dateSince && dateTill && user) {
      const dateSinceFormatted = setDateWithTimezone(dateSince, user.timezone)
      const dateTillFormatted = setDateWithTimezone(dateTill, user.timezone)
      setDateRange([dateSinceFormatted, dateTillFormatted])
      setAppliedDateSince(dateSinceFormatted)
      setAppliedDateTill(dateTillFormatted)
    }
  }, [date, dateSince, dateTill, user])

  return (
    <div>
      <ReactDatePicker
        ref={datePickerRef}
        shouldCloseOnSelect={false}
        selectsRange={true}
        monthsShown={2}
        selected={selectedDateSince}
        startDate={selectedDateSince}
        className={'w-full'}
        calendarClassName={'date-range-picker'}
        onCalendarClose={() => {
          setDateRange([appliedDateSince, appliedDateTill])
          setActiveFilter(selectedFilter)
        }}
        endDate={selectedDateTill}
        minDate={getDate(minAllowedDate, user?.timezone)}
        maxDate={maxDate}
        dateFormat="MMMM d, yyyy"
        onChange={date => {
          if (date) {
            setActiveFilter(undefined)
            setDateRange(date as [Date | null, Date | null])
          }
        }}
        locale={locale}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-bottom"
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        customInput={
          <ButtonInput
            dateRange={[appliedDateSince, appliedDateTill]}
            locale={user?.dashboardLocale}
            error={error}
            light={light}
            label={label}
            required={required}
            placeholderText={placeholder || t('global.dates')}
            className={className}
            description={description}
            small={small}
          />
        }
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          return (
            <div className="flex items-center justify-between px-2 py-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`${
                  prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'
                } rounded-full outline-none group ${
                  customHeaderCount === 0 ? 'visible' : 'invisible'
                }`}
              >
                <ArrowIonicLeftIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
              </button>
              <span className="text-base text-gray-900">
                {new Intl.DateTimeFormat(locale.code, { month: 'long', year: 'numeric' }).format(
                  monthDate,
                )}
              </span>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`${
                  nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'
                } rounded-full outline-none group ${
                  customHeaderCount === 1 ? 'invisible sm:visible' : 'visible sm:invisible'
                }`}
              >
                <ArrowIonicRightIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
              </button>
            </div>
          )
        }}
      >
        <div className="flex justify-center items-center gap-5 flex-col border-t md:border-l md:border-t-0 border-transparent sm:border-gray/30 py-2 pr-2 pl-5 w-full md:w-fit sm:m-auto">
          <ul className="hidden sm:flex sm:flex-col gap-5 w-1/2 md:w-full">
            {Object.values(DateRangeFilterEnum).map(filter => (
              <li key={filter}>
                <Chip
                  className={'w-full'}
                  disabled={!user || disabled}
                  onClick={() => {
                    setActiveFilter(filter)
                  }}
                  selected={filter === activeFilter}
                >
                  {t(dateRangeFilterLocoKeys[filter])}
                </Chip>
              </li>
            ))}
            {(customFilters || [getDefaultCustomFilter(minAllowedDate, user?.timezone)]).map(
              customFilter => (
                <li key={customFilter.slug}>
                  <Chip
                    className={'w-full'}
                    disabled={!user || disabled}
                    onClick={() => {
                      setActiveFilter(customFilter.slug)
                    }}
                    selected={customFilter.slug === activeFilter}
                  >
                    {t(dateRangeCustomFilterLocoKeys[customFilter.slug])}
                  </Chip>
                </li>
              ),
            )}
          </ul>
          <div className="flex justify-between gap-3 w-full sm:w-1/2 md:w-full">
            <DangerButton
              disabled={!user || disabled}
              onClick={() => {
                datePickerRef.current?.setOpen(false)
                if (getDefaultDate) {
                  const [dateSince, dateTill] = getDefaultDate()
                  const newDataSince = getDateSince(dateSince) ?? undefined
                  const dateSinceFormatted = setDateWithTimezone(newDataSince, user?.timezone)
                  const dateTillFormatted = setDateWithTimezone(dateTill, user?.timezone)
                  setAppliedDateTill(dateTillFormatted)
                  setAppliedDateSince(dateSinceFormatted)
                  onChange([
                    toISOStringWithoutTimezone(dateSinceFormatted),
                    toISOStringWithoutTimezone(dateTillFormatted),
                  ])
                } else {
                  setAppliedDateTill(null)
                  setAppliedDateSince(null)
                  onChange([null, null])
                }
                setSelectedFilter(undefined)
              }}
              className={'w-full md:w-fit'}
            >
              {t('global.clear')}
            </DangerButton>
            <PrimaryButton
              disabled={!dateRange[0] || !dateRange[1] || disabled || !user}
              className={'w-full md:w-fit'}
              onClick={() => {
                const [dateSince, dateTill] = dateRange
                if (dateSince && dateTill) {
                  setAppliedDateSince(dateSince)
                  setAppliedDateTill(dateTill)
                  setSelectedFilter(activeFilter)
                  setDateSince(dateSince)
                  setDateTill(dateTill)
                  onChange([
                    toISOStringWithoutTimezone(dateSince),
                    toISOStringWithoutTimezone(dateTill),
                  ])
                } else {
                  onChange([null, null])
                }
                datePickerRef.current?.setOpen(false)
              }}
            >
              {t('global.apply')}
            </PrimaryButton>
          </div>
        </div>
      </ReactDatePicker>
    </div>
  )
}

export default DateRangePicker

interface ButtonInputProps extends FieldLabelProps {
  dateRange?: [Date | null, Date | null]
  onClick?: () => void
  locale?: LocaleEnum
  error?: string
  light?: boolean
  placeholderText?: string
  className?: string
  description?: string
  small?: boolean
}

const ButtonInput = forwardRef<HTMLButtonElement, ButtonInputProps>(
  (
    {
      dateRange,
      onClick,
      locale,
      error,
      light,
      label,
      labelClassName,
      required,

      placeholderText,
      className,
      description,
      small,
    },
    ref,
  ) => (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      <button
        onClick={onClick}
        ref={ref}
        type="button"
        className={`flex group justify-between ${
          small ? '' : 'min-w-[150px] lg:min-w-[200px]'
        } w-full py-2.5 px-4 text-sm font-medium ${
          light ? 'text-[#6b7c93] font-avertape' : 'text-darkblue'
        } border ${
          error ? 'border-danger' : 'border-gray/30'
        } rounded-lg bg-white focus:outline-none focus:border-blue main-transition-colors`}
      >
        <span className="text-start whitespace-nowrap">
          {dateRange && dateRange[0] && dateRange[1] ? (
            <>
              <span className={'hidden lg:inline'}>{`${new Intl.DateTimeFormat(locale || 'en', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }).format(dateRange[0])} - ${new Intl.DateTimeFormat(locale || 'en', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }).format(dateRange[1])}
              `}</span>
              <span className={'inline lg:hidden'}>{`${new Intl.DateTimeFormat(locale || 'en', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }).format(dateRange[0])} - ${new Intl.DateTimeFormat(locale || 'en', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }).format(dateRange[1])}
              `}</span>
            </>
          ) : (
            <span className={'text-gray-300/70'}>{placeholderText}</span>
          )}
        </span>
        <CalendarIcon className="hidden sm:block ml-3 fill-gray-100 h-[20px] w-[20px] group-focus:fill-blue main-transition-colors" />
      </button>
      <FieldErrorAndDescription error={error} description={description} />
    </div>
  ),
)

ButtonInput.displayName = 'ButtonInput'
