import { CustomFilterType } from 'shared/components/date-picker/date-range-picker'
import { DateRangeCustomFilterEnum } from 'shared/components/date-picker/enum/date-range-filter-enum'
import { setDateWithTimezone } from 'shared/utils/date-time-format'

export const setAllTimeFilter = (dateSince?: string, timezone?: string): [Date, Date] => {
  const today = setDateWithTimezone('', timezone)
  today.setHours(23, 59, 59, 999)
  const firstDate = setDateWithTimezone(dateSince, timezone)
  return [firstDate, today]
}

export const setLast3MonthsFilter = (timezone?: string): [Date, Date] => {
  const today = setDateWithTimezone('', timezone)
  today.setHours(23, 59, 59, 999)
  const firstDate = setDateWithTimezone('', timezone)
  firstDate.setMonth(firstDate.getMonth() - 3)
  firstDate.setHours(0, 0, 0, 0)
  return [firstDate, today]
}

export const getDefaultCustomFilter = (
  dateSince?: string,
  timezone?: string,
): CustomFilterType => ({
  slug: DateRangeCustomFilterEnum.all_time,
  handler: () => setAllTimeFilter(dateSince, timezone),
})

export const getLast3MonthsCustomFilter = (timezone?: string) => ({
  slug: DateRangeCustomFilterEnum.last_3_months,
  handler: () => setLast3MonthsFilter(timezone),
})
