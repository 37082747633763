import { Dispatch, SetStateAction } from 'react'
import { CustomFilterType } from 'shared/components/date-picker/date-range-picker'
import {
  DateRangeCustomFilterEnum,
  DateRangeFilterEnum,
} from 'shared/components/date-picker/enum/date-range-filter-enum'
import { UserInterface } from 'shared/types/user-interface'
import { setDateWithTimezone } from 'shared/utils/date-time-format'

const isCustomFilter = (
  activeFilter: DateRangeCustomFilterEnum | DateRangeFilterEnum | undefined,
): activeFilter is DateRangeCustomFilterEnum => {
  return Object.values(DateRangeCustomFilterEnum).includes(
    activeFilter as DateRangeCustomFilterEnum,
  )
}

export const setActiveFilterValue = ({
  activeFilter,
  setDateRange,
  user,
  customFilters,
}: {
  activeFilter: DateRangeCustomFilterEnum | DateRangeFilterEnum | undefined
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>
  user: UserInterface
  customFilters: CustomFilterType[]
}) => {
  const isCustom = isCustomFilter(activeFilter)
  if (isCustom) {
    const handler = customFilters.find(custom => custom.slug === activeFilter)?.handler
    if (handler) {
      setDateRange(handler())
    }
  } else {
    switch (activeFilter) {
      case 'last_30_days': {
        const today: Date = setDateWithTimezone('', user.timezone)
        today.setHours(23, 59, 59, 999)
        const firstDate: Date = new Date(today)
        firstDate.setDate(firstDate.getDate() - 30)
        firstDate.setHours(0, 0, 0, 0)
        setDateRange([firstDate, today])
        break
      }
      case 'this_month': {
        const date = setDateWithTimezone('', user.timezone),
          y = date.getFullYear(),
          m = date.getMonth()
        const firstDay = new Date(y, m, 1)
        const lastDay = new Date(y, m + 1, 0)
        setDateRange([firstDay, lastDay])
        break
      }
      case 'last_month': {
        const date = setDateWithTimezone('', user.timezone),
          y = date.getFullYear(),
          m = date.getMonth()
        const firstDay = new Date(y, m - 1, 1)
        const lastDay = new Date(y, m, 0)
        setDateRange([firstDay, lastDay])
        break
      }
    }
  }
}
