export enum DateRangeFilterEnum {
  last_30_days = 'last_30_days',
  this_month = 'this_month',
  last_month = 'last_month',
}

export enum DateRangeCustomFilterEnum {
  all_time = 'all_time',
  last_3_months = 'last_3_months',
  evaluation_period = 'evaluation_period',
}
